import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

import "../mystyles.scss"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata ?.title || `Missing title`
  const { previous, next } = data

  const tags = post?.frontmatter?.tags?.split("|")
  const folder = post.frontmatter?.folder

  //Get relevant images
  const images = data.allFile.nodes.filter(
    i => {
      let localFolder = folder + '/Images'
      return i.dir.substring(i.dir.length - localFolder.length) === localFolder 
    } 
    
  )

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.brand}
        description={post.frontmatter.description || post.excerpt}
      />

      <div className="container block">
        <div className="columns is-centered">
          <div className="column is-two-thirds m-3">

            <div className="content has-text-warning">

              <header >
                <h1 className="title is-1 m-1" itemProp="headline">{post.frontmatter.brand}</h1>
                <h3 className="title is-3 m-1" itemProp="headline">{post.frontmatter.campaign}</h3>
                {/* <p>{post.frontmatter.date}</p> */}
              </header>

              <div className="tags">
                {tags?.map((tag, index) => {
                  return (
                    <span className="tag is-rounded has-background-success has-text-info is-4" key={index}>
                      {tag}
                    </span>
                  )
                })}
              </div>

              <article
                className="blog-post block"
                itemScope
                itemType="http://schema.org/Article"
              >
                <section
                  dangerouslySetInnerHTML={{ __html: post.html }}
                  itemProp="articleBody"
                  className="is-family-monospace"
                />
              </article>


              {/* insert all pictures */}

              <div className="images has-text-centered">
                {images?.map((image, index) => {
                  return (
                    <div key={index} className="block" >
                      <Img fluid={image.childImageSharp.fluid}
                        loading="eager"
                        style={{margin: "auto"}}
                      />
                    </div>
                  )
                })}
              </div>

            </div>
            {/* </div> */}


          </div>

        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        brand
        campaign
        date(formatString: "MMMM DD, YYYY")
        description
        folder
        mainImageName
        tags
        order
        tileHeader
      }
    }
    allFile(    filter: {dir: {regex: "/blog//"}, extension: {ne: "md"}, relativeDirectory: {regex: "/.*\/Images\/"}}
    ) {
      nodes {
        name
        dir
        relativeDirectory
        publicURL
        childImageSharp {
          fluid(maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize 
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
